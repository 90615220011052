<template>
  <span>
    {{ $t('components.availableGroupPrices.specifiedRangeOnly') }}: <br />
    <div v-if="availableGroupPrices.length">
      <span v-for="(groupPrice, index) in availableGroupPrices" :key="groupPrice.name">
        {{ index + 1 }}. {{ groupPrice.label }}
        <br />
      </span>
    </div>
    <div v-else>{{ $t('components.availableGroupPrices.rangeNotFound') }}</div>
  </span>
</template>
<script>
export default {
  name: 'AvailableGroupPrices',
  props: {
    availableGroupPrices: { type: Array, required: true },
  },
};
</script>
