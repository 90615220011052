<template>
  <div>
    <small v-if="inProductItem"
      >{{ $t('components.quantityInput') }}: {{ supply > 0 ? supply : $t('helpers.noData') }}</small
    >
    <div class="d-flex flex-row align-center justify-center">
      <v-tooltip bottom :disabled="!disabledMinus">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-btn :disabled="disabled || disabledMinus" icon @click="decrementQuantity">
              <v-icon color="red"> mdi-minus-circle-outline </v-icon>
            </v-btn>
          </div>
        </template>
        <available-group-prices
          :availableGroupPrices="availableGroupPrices"
        ></available-group-prices>
      </v-tooltip>
      <input
        class="quantity"
        :min="min"
        :max="supply"
        :disabled="disabled"
        v-model.lazy="currQuantity"
        persistent-hint
        @blur="onBlur"
        elevation="0"
        outlined
        solo
      />
      <v-tooltip bottom :disabled="!disabledPlus">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-btn
              class="mr-3"
              :disabled="disabled || disabledPlus"
              icon
              @click="incrementQuantity"
            >
              <v-icon color="green"> mdi-plus-circle-outline</v-icon>
            </v-btn>
          </div>
        </template>
        <available-group-prices
          :availableGroupPrices="availableGroupPrices"
        ></available-group-prices>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { gte, lte } from 'lodash';
import AvailableGroupPrices from './AvailableGroupPrices.vue';
import { toNumber } from 'lodash/lang';
export default {
  name: 'QuantityInput',
  components: { AvailableGroupPrices },
  props: {
    quantity: { type: Number, default: 0 },
    supply: { type: Number, default: Number.MAX_SAFE_INTEGER },
    disabled: { type: Boolean, default: false },
    min: { type: Number, default: 0 },
    inProductItem: { type: Boolean, default: false },
    availableGroupPrices: { type: Array },
    quantityIncrement: { type: Number, default: 1 },
    startingQuantity: { type: Number, default: 0 },
    isOnWizard: { type: Boolean, default: false },
  },
  data() {
    return {
      currQuantity: 1,
      parsedQuantityIncrement: 1,
    };
  },
  created() {
    this.parsedQuantityIncrement = this.isOnWizard ? 1 : parseInt(this.quantityIncrement);

    if (this.startingQuantity > this.supply) {
      this.setQuantity(this.supply);
    } else {
      this.setQuantity(this.startingQuantity);
    }
  },
  computed: {
    disabledMinus() {
      return this.disabled || this.currQuantity <= this.min;
    },
    disabledPlus() {
      return this.disabled || this.currQuantity >= this.supply;
    },
  },
  watch: {
    currQuantity(newVal) {
      if (!this.isQuantityAllowedInAvailableGroups(newVal)) {
        this.setQuantity(this.quantity);
        return;
      }

      if (newVal > this.min && newVal <= this.supply) {
        this.setQuantity(newVal);
      } else {
        this.setQuantity(this.quantity);
      }

      if (!this.isOnWizard) {
        const remainder = newVal % this.parsedQuantityIncrement;
        if (remainder !== 0) {
          const correctVal = newVal - remainder + this.parsedQuantityIncrement;

          this.setQuantity(correctVal);
        }
      }
    },
  },
  methods: {
    setQuantity(quantity) {
      this.currQuantity = quantity > this.supply ? this.supply : quantity;
      this.$emit('quantityChange', quantity);
    },
    onBlur() {
      const quantity = toNumber(this.currQuantity);
      if (!Number.isNaN(quantity)) {
        this.setQuantity(quantity);
      }
    },
    incrementQuantity() {
      if (!this.disabledPlus) {
        const incrementedQuantity = this.currQuantity + this.parsedQuantityIncrement;

        if (this.supply < incrementedQuantity) {
          this.setQuantity(this.supply);
        } else {
          this.setQuantity(incrementedQuantity);
        }
      }
    },
    decrementQuantity() {
      if (!this.disabledMinus) {
        this.currQuantity -= this.parsedQuantityIncrement;
      }
    },
    isQuantityAllowedInAvailableGroups(quantity) {
      if (!this.inProductItem) {
        return true;
      }

      return this.availableGroupPrices.some(({ limitations: { min, max } }) => {
        return gte(quantity, min) && lte(quantity, max);
      });
    },
  },
};
</script>

<style lang="scss">
.quantity {
  margin: 0 0.25rem 0.25rem 0.25rem;
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  width: 75px;
  text-align: center;
  border: 0.125rem solid #c4c4c4;
}
</style>
